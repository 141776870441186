<template>
  <div class="preset-set">
    <el-form :model="formData" ref="formRef" label-width="auto">
      <!-- 1. 门店选择 -->
      <el-form-item label="门店:">
        <el-select
          v-model="formData.storeId"
          filterable
          placeholder="请选择门店"
          @change="handleStoreChange"
          style="width: 300px"
        >
          <el-option v-for="(item, index) in storeList" :key="index" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>

      <!-- 2. 开放渠道 -->
      <el-form-item label="开放渠道:">
        <el-checkbox-group v-model="formData.channel">
          <el-checkbox label="MINI">小程序</el-checkbox>
          <el-checkbox label="MT" disabled>美团(暂不支持)</el-checkbox>
          <el-checkbox label="DY" disabled>抖音(暂不支持)</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <!-- 3. 接单方式 -->
      <el-form-item label="接单方式:">
        <el-radio-group v-model="formData.takeOrders">
          <el-radio :label="0">自动接单</el-radio>
          <el-radio :label="1">手动接单</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- 4. 门店营业时间 -->
      <el-form-item label="门店营业时间:">
        <div style="display: flex; align-items: center">
          <div class="time-box">
            {{ formData.storeStartTime }}
          </div>
          <span class="time-separator">~</span>
          <div class="time-box">
            {{ formData.storeEndTime }}
          </div>
        </div>
      </el-form-item>

      <!-- 5. 可预订下单时间 -->
      <el-form-item label="可预订下单时间:">
        <div style="display: flex; align-items: center">
          <el-select v-model="formData.startTime" placeholder="开始时间" @change="handleStartTimeChange">
            <el-option
              v-for="(time, index) in timeOptions"
              :key="index"
              :label="time.label"
              :value="time.label"
            ></el-option>
          </el-select>
          <span class="time-separator">~</span>
          <el-select v-model="formData.endTime" placeholder="结束时间" @change="handleEndTimeChange">
            <el-option
              v-for="(time, index) in timeOptions"
              :key="index"
              :label="time.label"
              :value="time.label"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>

      <!-- 提示信息 -->
      <div class="tips">
        <p>1. 配置接单时间以保证及时接单；建议设置范围为“门店营业开始前30分钟~营业结束时间”，保证及时接单；</p>
        <p>2. 在接单时间范围内，您需在15分钟内完成接单，若未在15分钟内完成接单，系统将进行拒单。</p>
      </div>
      <!-- 6. 预订通知方式 -->
      <el-form-item label="预订通知方式 接单通知:">
        <el-checkbox-group v-model="formData.reservationNotice">
          <el-checkbox label="POS-MESSAGE">POS收银机消息</el-checkbox>
          <el-checkbox label="VOICE" :disabled="true">语音播报</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <!-- 7. 提前预订时间 -->
      <el-form-item label="提前预订时间:">
        <span>顾客需要提前</span>&nbsp;
        <el-select v-model="formData.minutes" :disabled="true" style="width: 120px">
          <el-option label="0分钟" :value="0"></el-option>
          <el-option label="15分钟" :value="15"></el-option>
          <el-option label="30分钟" :value="30"></el-option>
          <el-option label="1小时" :value="60"></el-option>
        </el-select>
        &nbsp;<span>预订</span>
      </el-form-item>

      <!-- 8. 保存按钮 -->
      <el-form-item>
        <el-button style="width: 200px;border-radius: 20px" type="primary" @click="handleSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup name="PresetSet">
import commonApi from '@/api'
import { ref, onMounted, reactive } from 'vue'
import API from '@/plugin/storeTransactionManager/service/api'
import { ElMessage } from 'element-plus'

let formData = reactive({
  storeId: '',
  channel: ['MINI'],
  startTime: '',
  endTime: '',
  reservationNotice: ['POS-MESSAGE'],
  minutes: 0,
  takeOrders: 0,
  storeStartTime: '',
  storeEndTime: '',
})

let storeList = ref([])
// 获取门店列表
const getStoreList = async () => {
  const res = await commonApi.getNewShop({})
  if (Number(res.code) === 200) {
    storeList.value =
      res.data.map(item => ({
        ...item,
        name: item.code + '--' + item.name,
      })) || []
    formData.storeId = storeList.value[0].code
  } else {
    ElMessage.error(res.msg)
  }
}
// 获取门店预定设置
const getPresetSet = async () => {
  const res = await API.getPresetSet({ storeCode: formData.storeId })
  if (Number(res.code) === 200) {
    const data = res.data || {}
    // 安全地处理数据，确保所有必要的字段都有默认值
    const safeData = {
      storeName: data.storeName || '',
      channel: data.channel || [],
      startTime: data.startTime || '',
      endTime: data.endTime || '',
      reservationNotice: data.reservationNotice || ['POS-MESSAGE'],
      minutes: data.minutes || 0,
      openingQuantum: data.openingQuantum || '',
      takeOrders: data.takeOrders || 0,
    }

    // 如果有营业时间，则处理时间范围
    if (safeData.openingQuantum) {
      const timeRange = safeData.openingQuantum.split('~')
      if (timeRange.length === 2) {
        safeData.storeStartTime = timeRange[0]
        safeData.storeEndTime = timeRange[1]
      }
    }

    Object.assign(formData, safeData)
  } else {
    ElMessage.error(res.msg)
  }
}

onMounted(async () => {
  await getStoreList()
  await getPresetSet()
})

const handleStoreChange = storeId => {
  if (storeId) {
    formData.storeId = storeId
    getPresetSet()
  }
}

// 生成时间选项
const generateTimeOptions = () => {
  const options = []
  const minutes = ['00', '30'] // 30分钟间隔

  // 生成当天时间（08:00-23:30）
  for (let hour = 8; hour < 24; hour++) {
    minutes.forEach(minute => {
      const time = `${hour.toString().padStart(2, '0')}:${minute}`
      options.push({
        label: time,
        value: time,
      })
    })
  }

  // 生成次日时间（00:00-08:00）
  for (let hour = 0; hour <= 8; hour++) {
    // 如果是8点，只添加00分钟
    if (hour === 8) {
      options.push({
        label: '次日08:00',
        value: '08:00',
      })
    } else {
      minutes.forEach(minute => {
        const time = `${hour.toString().padStart(2, '0')}:${minute}`
        options.push({
          label: `次日${time}`,
          value: time,
        })
      })
    }
  }

  return options
}

const timeOptions = ref(generateTimeOptions())

// 验证时间
const validateTime = () => {
  // 获取时间选项的标签，用于判断是否是次日时间
  const getTimeLabel = time => {
    const option = timeOptions.value.find(opt => opt.value === time)
    return option ? option.label : ''
  }

  const startTimeLabel = getTimeLabel(formData.startTime)
  const endTimeLabel = getTimeLabel(formData.endTime)

  // 判断是否是次日时间
  const isStartNextDay = startTimeLabel.startsWith('次日')
  const isEndNextDay = endTimeLabel.startsWith('次日')
  // 场景1：开始时间是次日，结束时间是当日，这种情况应该被禁止
  if (isStartNextDay && !isEndNextDay) {
    ElMessage.warning('开始时间不能大于结束时间')
    return false
  }

  // 场景2：开始时间和结束时间都是当日
  if (!isStartNextDay && !isEndNextDay) {
    if (formData.startTime >= formData.endTime) {
      ElMessage.warning('开始时间不能大于或等于结束时间')
      return false
    }
    return true
  }

  // 场景3：开始时间是当日，结束时间是次日
  if (!isStartNextDay && isEndNextDay) {
    return true
  }

  // 场景4：开始时间和结束时间都是次日
  if (isStartNextDay && isEndNextDay) {
    if (formData.startTime >= formData.endTime) {
      ElMessage.warning('开始时间不能大于或等于结束时间')
      return false
    }
    return true
  }

  return true
}
const formRef = ref()
const handleSubmit = () => {
  formRef.value.validate(async valid => {
    if (valid) {
      if (!validateTime()) {
        return
      }
      const user = JSON.parse(localStorage.getItem('vuex'))
      const params = {
        storeCode: formData.storeId,
        storeName: formData.storeName,
        takeOrders: formData.takeOrders,
        startTime: formData.startTime,
        endTime: formData.endTime,
        createUser: user.user.userInfo.userSaasUserVO.userName,
        channel: formData.channel,
        reservationNotice: formData.reservationNotice,
        minutes: formData.minutes,
      }
      const res = await API.updatePresetSet(params)
      if (Number(res.code) === 200) {
        ElMessage.success('更新成功')
        getPresetSet()
      } else {
        ElMessage.error(res.msg)
      }
    }
  })
}
</script>

<style lang="scss" scoped>
.preset-set {
  padding: 30px;

  .next-day {
    margin-right: 5px;
    color: #606266;
  }

  .tips {
    margin: 10px 0;
    padding: 10px;
    color: #e8a045;
    border-radius: 4px;
    margin-left: 150px;

    p {
      margin: 5px 0;
      font-size: 14px;
    }
  }
  .time-box {
    height: 40px;
    line-height: 40px;
    // border: 1px solid #ccc;
    text-align: center;
    border-radius: 4px;
  }
  .time-separator {
    margin: 0 10px;
  }
}
::v-deep .el-input__inner {
  text-align: center;
  background: #f8faff;
}
</style>
